import React, { Fragment, PureComponent } from 'react';
import _ from 'lodash';
import loadable from '@loadable/component';

import { ICON_MENU } from 'common/constants/IconConstants';

import { BlockFlex } from 'common/components/styleguide/grid';
const Column = loadable(() => import('common/components/styleguide/grid/Column'),{ ssr: true });
const Row = loadable(() => import('common/components/styleguide/grid/Row'), { ssr: true });
const Container = loadable(() => import('common/components/styleguide/grid/Container'), { ssr: true });
const Icon = loadable(() => import('common/components/styleguide/Icon'), {ssr: true});
const Link = loadable(() => import('common/components/styleguide/Link'), {ssr: true});
import Image from 'common/components/_base/Image';

import { withCssUnit } from 'common/components/styleguide/utils/index';
import { getLogoPath } from 'common/utils';

const SearchbarSection = loadable(
  () =>
    import('common/components/_partials/header/main/_default/SearchbarSection'),
  { ssr: false }
);
const UserButton = loadable(
  () => import('common/blocks/functional/userButton'),
  { ssr: false }
);
const CartButton = loadable(
  () => import('common/blocks/functional/cartButton'),
  { ssr: false }
);

class HeaderVariant1 extends PureComponent {
  render() {
    const { theme, logo, isNavbarEnabled, children, toggleNav } = this.props;
    const customWrapperStyle = `
      position: relative;
      @media (max-width: ${withCssUnit(_.get(theme, 'breakpoints.md'))}) {
        margin-bottom: 55px;
      }`;
    return (
      <Fragment>
        <Container>
          <Row>
            <Column size={{ xs: 12 }}>
              <BlockFlex
                marginConfig={{ top: true, bottom: true }}
                customStyle={customWrapperStyle}
              >
                <BlockFlex>
                  {isNavbarEnabled ? (
                    <Column
                      visible={{ xs: true, sm: true, md: false, lg: false }}
                      customStyle={'padding: 0; flex-basis: auto;'}
                    >
                      <Link href="#" linkType={'text'} onClick={toggleNav}>
                        <Icon className={ICON_MENU} />
                      </Link>
                    </Column>
                  ) : null}
                  <Link href="/" className="logo">
                    <Image
                      path={getLogoPath(logo)}
                      srcSet={{
                        '780px': '140x40.png',
                        '1240px': '210x60.png'
                      }}
                      size={'210x60.png'}
                      alt={
                        _.get(logo, 'description', false)
                          ? logo.description
                          : 'logo'
                      }
                      lazyload={false}
                    />
                  </Link>
                </BlockFlex>
                <SearchbarSection />
                <BlockFlex>
                  <BlockFlex marginConfig={{ left: true, right: true }}>
                    <UserButton />
                  </BlockFlex>
                  <CartButton />
                </BlockFlex>
              </BlockFlex>
            </Column>
          </Row>
        </Container>
        {children}
      </Fragment>
    );
  }
}

export default HeaderVariant1;
