import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { Row, Column } from 'common/components/styleguide/grid';

import Image from 'common/components/_base/Image';
import Link from 'common/components/styleguide/Link';

const Item = styled.div`
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
`;

const BannerVariant3 = ({
  items = [],
  scrSet = {
    '460w': '325x140.png',
    '780w': '650x280.png'
  },
  size = '325x140.png'
  }) => {
    if (_.isEmpty(items)) {
      return null;
    }
    return (
      <Row>
        {items.map((item, i) => {
          return (
            <Column key={'banner-variant3-item-' + _.get(item, 'id', i)} size={{ xs: 12, md: 6 }}>
              <Item >
                {item.url ? (
                  <Link href={item.url}>
                    <Image
                      {...item.photo}
                      srcSet={scrSet}
                      size={size}
                      sizes="100vw"
                      alt={_.get(item, 'photo.description', false) ? item.photo.description : item.title }
                      lazyloadPlaceholder={
                        <Image
                          {...item.photo}
                          lazyload={false}
                          size={size}
                        />
                      }
                    />
                  </Link>
                ) : (
                  <Image
                    {...item.photo}
                    srcSet={scrSet}
                    size={size}
                    sizes="100vw"
                    alt={_.get(item, 'photo.description', false) ? item.photo.description : item.title }
                    lazyloadPlaceholder={
                      <Image {...item.photo} lazyload={false} size={size} />
                    }
                  />
                )}
              </Item>
            </Column>
          );
        })}
      </Row>
    );
};

export default BannerVariant3;
