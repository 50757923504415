import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { Row, Column } from 'common/components/styleguide/grid';

import Image from 'common/components/_base/Image';
import Link from 'common/components/styleguide/Link';
import { Headline3 } from 'common/components/styleguide/Headline';

const Item = styled.div`
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
`;

const BannerVariant4 = ({
  items = [],
  scrSet = {
    '460w': '325x140.png',
    '780w': '650x280.png'
  },
  size = '325x140.png'
  }) => {
    if (_.isEmpty(items)) {
      return null;
    }
    const item = _.head(items);
    return (
      <Row>
        <Column size={{ xs: 12, md: 6 }}>
          <Item >
            {item.url ? (
              <Link href={item.url}>
                <Image
                  {...item.photo}
                  srcSet={scrSet}
                  size={size}
                  sizes="100vw"
                  alt={_.get(item, 'photo.description', false) ? item.photo.description : item.title}
                  lazyloadPlaceholder={
                    <Image
                      {...item.photo}
                      lazyload={false}
                      size={size}
                    />
                  }
                />
              </Link>
            ) : (
              <Image
                {...item.photo}
                srcSet={scrSet}
                size={size}
                sizes="100vw"
                alt={_.get(item, 'photo.description', false) ? item.photo.description : item.title}
                lazyloadPlaceholder={
                  <Image {...item.photo} lazyload={false} size={size} />
                }
              />
            )}
          </Item>
        </Column>
        <Column size={{ xs: 12, md: 6 }}>
            <Headline3>{item.title}</Headline3>
            <div  dangerouslySetInnerHTML={{
              __html: item.text
            }}></div>
        </Column>
      </Row>
    );
};

export default BannerVariant4;
